

<template>

<v-app id="ci-home">
  <Header />
  <v-main class="ci-app-content" >   
    <v-btn  v-scroll="onScroll"  v-show="fab" fab  dark fixed  bottom  right  color="primary"  @click="toTop" >     
      <v-icon>keyboard_arrow_up</v-icon>
    </v-btn>
    <div  id="ci-search-content" v-if="zeigeSuchfelder">
       <div class="searchbar">
        <SearchBar />
       </div>
    </div> 
    
    <List v-if="false && daten && daten.liste" />
    <Einzelsatz v-if="false && daten && daten.einzelsatz" ref="einzelsatz"/>
    <SchemaEditor v-if="false &&  daten && daten.schemaeditor" />
  
    <SchemaEditor />
  
  </v-main>
  <Footer />
  <AlertDialog />
</v-app>
</template>
<script>
import { mapGetters } from "vuex";
import { FETCH_PAGE} from "../store/actions.type";
import List from "@/components/List.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Einzelsatz from "@/components/Einzelsatz.vue";
import SearchBar from "@/components/SearchBar.vue";
import AlertDialog from "@/components/AlertDialog.vue";
import SchemaEditor from "@/components/SchemaEditor.vue";



export default {
  name: "home",
  template:  '#home-template',
  data() {
    return {
      fab: false,
    };
  },
  components: {
    List,
    Header,
    Einzelsatz,
    Footer,
    SearchBar,
    SchemaEditor,
    AlertDialog

  },
  computed: {
    ...mapGetters(["daten", "zeigeSuchfelder"])
  },
  methods: {
    fetchData() {
     // console.log(this.$route.params)
      //this.$store.dispatch(FETCH_PAGE, this.$route.params);    
      this.$store.dispatch(FETCH_PAGE, {pfkt: 10002, p_id: 10005407 });    
  
    },
    onScroll (e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset ||   e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop () {
      let options = {
        duration: 0
      }
      this.$vuetify.goTo(0, options)
    }
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    // call again the method if the route changes
    $route: "fetchData"
  }
};
</script>